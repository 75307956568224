import React from 'react';
import './projectsHeader.css';

function ProjectsHeader() {
    return (
        <div>
            <div className="Projects-header">
        <header>
            <h2 className="ProjectsGlow">Projects</h2>
        </header>
        <p className='ProjectsBlerb'>Here are some of the projects that I have been working on </p>
            </div>
        </div>

    );
}

export default ProjectsHeader;

