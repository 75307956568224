import React from 'react';
import CTA from '../CTA/cTA';
import './cTABanner.css';

function CTABanner() {
  return (
    <div className="CTABanner"> 
    <CTA/>
    </div>
  );
}

export default CTABanner;
