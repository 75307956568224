import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import React from 'react';


interface Project {Title: string, Description: string, Link: string, Image: string, Languages: string[], Frameworks: string[], Tags: string[], Year: number, Index: number};

const ProjectsList: Project[] = [
    {Title: 'C-Sharp-Calculator', Description: 'A simple calculator made in C#', Link: 'https://github.com/Vaporjawn/C-Sharp-Calculator', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['C#'], Frameworks: ['.NET'], Tags: ['calculator', 'csh', 'csharp', 'equation', 'csharp-code', 'csharp-script', 'csharp-core', 'csharp-library', 'csharp7', 'cshtml', 'cshap'], Year: 2020, Index: 1},
    {Title: 'Checkers', Description: 'The classic game of checkers made using HTML5 and Javascript', Link: 'https://github.com/Vaporjawn/Checkers', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML','CSS'], Frameworks: ['JQuery','SASS','HTML5'], Tags: ['css', 'game', 'sass', 'jquery', 'board-game', 'html5', 'favicon', 'classic', 'boardgame', 'checkers', 'vaporjawn'], Year: 2020, Index: 2},
    {Title: 'Graphing-Calculator', Description: 'A fully functioning graphing calculator made out of JavaScript', Link: 'https://github.com/Vaporjawn/Graphing-Calculator', Image: 'https://github.com/Vaporjawn/Graphing-Calculator/raw/master/website.png', Languages: ['Javascript','HTML'], Frameworks: ['Bootstrap'], Tags: ['javascript', 'calculator', 'calculator-application', 'calculator-javascript', 'calculator-application', 'calculator-app',], Year: 2020, Index: 3},
    {Title: 'Javascript-Calculator', Description: 'A Calculator made out of Javascript and ES6', Link: 'https://github.com/Vaporjawn/Javascript-Calculator', Image: 'https://github.com/Vaporjawn/Javascript-Calculator/raw/master/website.png', Languages: ['Javascript','HTML','CSS'], Frameworks: ['Bootstrap'], Tags: ['javascript', 'css', 'html', 'calculator', 'html5', 'script', 'javascript-calculator', 'caclulator'], Year: 2020, Index: 4},
    {Title: 'Maze.JS', Description: 'A maze generator made out of JavaScript', Link: 'https://github.com/Vaporjawn/Maze.JS', Image: 'https://github.com/Vaporjawn/Maze.JS/raw/master/website.png', Languages: ['Javascript'], Frameworks: ['P5'], Tags: ['javascript', 'maze', 'maze-generator', 'maze-algorithms', 'maze-game', 'mazes', 'maze-solver', 'maze-creation', 'maze-generation-algorithms', 'mazerunner', 'maze-explorer'], Year: 2020, Index: 5},
    {Title: 'Mock-Store', Description: 'A webapp UI skeleton for a software store', Link: 'https://github.com/Vaporjawn/Mock-Store', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML','CSS'], Frameworks: ['JQuery','SASS','HTML5'], Tags: ['Mock', 'Store'], Year: 2020, Index: 6},
    {Title: 'Prime-Number-Finder', Description: 'A java program that finds prime numbers', Link: 'https://github.com/Vaporjawn/Prime-Number-Finder', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Java'], Frameworks: ['NetBeans'], Tags: ['python', 'java', 'finder', 'prime', 'number', 'primenumbers', 'primenumbergenerator'], Year: 2016, Index: 7},
    {Title: 'React-Tac-Toe', Description: 'A tic tac toe game made in React', Link: 'https://github.com/Vaporjawn/React-Tac-Toe', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','CSS'], Frameworks: ['React', 'JSX'], Tags: ['React', 'JSX'], Year: 2020, Index: 8},
    {Title: 'ShortURL', Description: 'ShortURL is, as the name suggests, a URL shortener that is comprised of JavaScript along with modules and views', Link: 'https://github.com/Vaporjawn/ShortURL', Image: 'https://github.com/Vaporjawn/Retro-Catch-the-Block/raw/master/website.png', Languages: ['EJS', 'JavaScript'], Frameworks: ['EJS'], Tags: ['EJS','URL'], Year: 2021, Index: 9},
    {Title: 'Drexel Transportation', Description: 'An application for Drexel University students to track their buses with', Link: 'https://devpost.com/software/project-pjeziwx65day', Image: 'https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/348/578/datas/gallery.jpg', Languages: ['Javascript','HTML', 'CSS', 'MYSQL', 'Java'], Frameworks: ['HTML5', 'MYSQL'], Tags: ['api','chrome', 'css', 'html5', 'java', 'javascript', 'mysql', 'sublime-text'], Year: 2020, Index: 10},
    {Title: 'Esperanta-Klavaro', Description: 'An Esperanto Keyboard for iOS', Link: 'https://github.com/Vaporjawn/Esperanta-Klavaro', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML'], Frameworks: ['HTML5'], Tags: ['javascript', 'css', 'game', 'html', 'html5', 'snake', 'snake-game', 'fruit', 'snek', 'snakes'], Year: 2020, Index: 11},
    {Title: 'Sud0ku', Description: 'A Sud0ku game and solver made in python', Link: 'https://github.com/Vaporjawn/Sud0ku', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','Python', 'HTML'], Frameworks: ['HTML5'], Tags: ['python', 'gui', 'algorithm', 'pencil', 'sudoku-solver', 'sudoku-puzzle', 'sudoku', 'sudoku-generator', 'sudoku-checker', 'sudoku-solution-finder', 'gitpod', 'video-tutorials'], Year: 2020, Index: 12},
    {Title: 'Websites', Description: 'Websites that i coded myself', Link: 'https://github.com/Vaporjawn/websites', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: ['javascript', 'css', 'html', 'template', 'website', 'example'], Year: 2020, Index: 13},
    {Title: 'Spell-Checker', Description: 'An operational spell checker made out of python, using a trainer and tests', Link: 'https://github.com/Vaporjawn/Spell-Checker', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML'], Frameworks: ['HTML5'], Tags: ['spellcheck', 'spelling', 'spell-check', 'spelling-checker', 'spell', 'spell-checker', 'spelling-correction', 'spelling-corrector', 'spellchecker'], Year: 2020, Index: 14},
    {Title: 'Hangman', Description: 'The classic game of hangman made in Java', Link: 'https://github.com/Vaporjawn/Hangman', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Java'], Frameworks: ['Java'], Tags: [], Year: 2020, Index: 15},
    {Title: 'Bank', Description: 'A bank made in Java', Link: 'https://github.com/Vaporjawn/Bank', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Java'], Frameworks: ['Java'], Tags: [], Year: 2016, Index: 16},
    {Title: 'Android-Image-Resizer', Description: 'Resizes local photos using React Native', Link: 'https://github.com/Vaporjawn/Android-Image-Resizer', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Java','Objective-C', 'JavaScript', 'Ruby', 'Starlark'], Frameworks: ['ReactNative'], Tags: [], Year: 2021, Index: 17},
    {Title: 'Tic-Tac-Toe', Description: 'The Classic game of Tic Tac Toe made in HTML, CSS, and Javascript', Link: 'https://github.com/Vaporjawn/Tic-Tac-Toe', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: ['react', 'javascript', 'css', 'game', 'html', 'tictactoe', 'tic', 'tac', 'toe'], Year: 2020, Index: 18},
    {Title: 'RGB-Calculator', Description: 'Calculates the RGB combination with a UI that shows you the resulting color', Link: 'https://github.com/Vaporjawn/RGB-Calculator', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 19},
    {Title: 'GameBoy-Snake-Game', Description: 'The snake game with a gameboy overlay for the web browser', Link: 'https://github.com/Vaporjawn/GameBoy-Snake-Game', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 20},
    {Title: 'Weather-WebApp', Description: 'A weather app made in Javascript', Link: 'https://github.com/Vaporjawn/Weather-WebApp', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 21},
    {Title: 'Travel-Logger', Description: 'Fullstack Javascript and JSON web-based travel log', Link: 'https://github.com/Vaporjawn/Travel-Logger', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2021, Index: 22},
    {Title: 'AutoHD-for-Youtube', Description: 'Automatically selects the best available hd quality or the user\'s preferred quality.', Link: 'https://github.com/Vaporjawn/AutoHD-for-Youtube', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 23},
    {Title: 'Bouncing-DVD-Algorithm', Description: 'simulates the “Bouncing DVD Logo”', Link: 'https://github.com/Vaporjawn/Bouncing-DVD-Algorithm', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 24},
    {Title: 'Black-Hole-Visualization', Description: 'visualizes the behavior or light (photons) following the path of spacetime around the black hole.', Link: 'https://github.com/Vaporjawn/Black-Hole-Visualization', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 25},
    {Title: 'Bubble-Sort-Visualization', Description: 'A visualization of the sorting algorithm BubbleSort made in Python', Link: 'https://github.com/Vaporjawn/Bubble-Sort-Visualization', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 26},
    {Title: 'Feel-The-Bern', Description: 'A Chrome Extension that Berns your Browser', Link: 'https://chrome.google.com/webstore/detail/feel-the-bern/gjepmnpnapkdgappbjneblfdnjkbnjia', Image: 'https://lh3.googleusercontent.com/gAcjKthGUTrpaTyJlZYohN1L1GTKdXjIKlZWbUbK-me9T3ZTUmyjywP9yhiYU3ltjMapOBCaC0sGCp5mZXWu0-gT=w640-h400-e365-rj-sc0x00ffffff', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 27},
    {Title: 'GOP-Candidates', Description: 'This is made as a contrast to my Bernie chrome extension.', Link: 'https://github.com/Vaporjawn/GOP-Candidates', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 28},
    {Title: 'Word-Generator', Description: 'A webpage that allows you to generate a random word', Link: 'A webpage that allows you to generate a random word', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 29},
    {Title: 'Retro-Catch-the-Block', Description: 'A retro style version of Catch the Block made with HTML and Javascript', Link: 'https://github.com/Vaporjawn/Retro-Catch-the-Block', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 31},
    {Title: 'Dsxyliea', Description: 'constantly shifting text on your browser', Link: 'https://github.com/Vaporjawn/Dsxyliea', Image: 'https://raw.githubusercontent.com/Vapor-jawn/Instagram-Pictures/main/images/CodingProject.jpg.jpg', Languages: ['Javascript','HTML', 'CSS'], Frameworks: ['HTML5'], Tags: [], Year: 2020, Index: 32},
];


export default ProjectsList;
