import React from 'react';
import CTA from '../../CTA/cTA';
import './smashCTABanner.css';

function SmashCTABanner() {
  return (
    <div className="smashCTABanner"> 
    <CTA/>
    </div>
  );
}

export default SmashCTABanner;
