import React from 'react';
import CTA from '../../CTA/cTA';
import './projectsCTABanner.css';

function ProjectsCTABanner() {
  return (
    <div className="ProjectsCTABanner"> 
    <CTA/>
    </div>
  );
}

export default ProjectsCTABanner;
