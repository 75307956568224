import React from 'react';
import CTA from '../../CTA/cTA';
import './vaporjawnCTABanner.css';

function VaporjawnCTABanner() {
  return (
    <div className="VaporjawnCTABanner"> 
    <CTA/>
    </div>
  );
}

export default VaporjawnCTABanner;
