import React from 'react';
import CTA from '../../CTA/cTA';
import './hackathonCTABanner.css';

function HackathonCTABanner() {
  return (
    <div className="hackathonCTABanner"> 
    <CTA/>
    </div>
  );
}

export default HackathonCTABanner;
